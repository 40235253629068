import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import dayjs from "dayjs";
import {IHasTags} from "../IHasTags";
import {ESensorType} from "../EDeviceStatus";


@jsonObject()
export class VehicleBase implements IHasTags {
    @jsonMember(String)
    name: string;
    @jsonMember(Number)
    insurancePolicyNumber?: number;

    @jsonMember(String)
    tags?: string;

    @jsonMember(String)
    notes?: string;
    @jsonMember(String)
    organisationId?: string;

    @jsonMember(Number)
    insurance2PolicyNumber?: Number;

    @jsonMember(String)
    make?: string;

    @jsonMember(String, {
        name: 'device',
        deserializer: (value: any) => {
            if (typeof value === 'string') {
                return value;
            }
            return value && value.id;
        }
    })
    losantDeviceId?: string;

    @jsonMember(Number)
    engine?: number;
    @jsonMember(Number)
    horsePower?: number;
    @jsonMember(Number)
    fuelGrade?: number;
    @jsonMember(Number)
    transmission?: number;
    @jsonMember(Number)
    fuel?: number;
    @jsonMember(Number)
    voltage_level?: number;
    @jsonMember(Number)
    fuelCapacity?: number;
    @jsonMember(Number)
    wheelDiameter?: number;
    @jsonMember(Number)
    wheelWidth?: number;
    @jsonMember(Number)
    tyreNumber?: number;
    @jsonMember(Number)
    tyreSize?: number;
    @jsonMember(Number)
    length?: number;
    @jsonMember(Number)
    width?: number;
    @jsonMember(Number)
    height?: number;
    @jsonMember(Number)
    weight?: number;
    @jsonMember(Number)
    passengerCapacity?: number;
    @jsonMember(String)
    insurance2ValidTill?: string;
    @jsonMember(String)
    location?: string;
    @jsonMember(String)
    model?: string;
    @jsonMember(String)
    year?: string;
    @jsonMember(String)
    color?: string;
    @jsonMember(String)
    vin?: string;
    @jsonMember(String)
    type: string;
    @jsonMember(String)
    subType?: string;
    @jsonMember(String)
    stockNumber?: string;
    @jsonMember(String)
    insuranceValidTill?: string;
    @jsonMember(String)
    vehicleProfile?: string;

    sensorType: ESensorType = ESensorType.VEHICLE;


    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;
    @jsonMember(String)
    id: string;


    get isAlarmArmed(): boolean {
        return false;
    }

    get uiHeight(): number {
        return 84;
    }

    getVinNumber() {
        return (this.vin)
            ? `VN-${this.vin}`
            : '-';
    }

    getTagValue(name: string): string | null {
        return null;
    }
}

export const VehicleBaseSerializer = new TypedJSON(VehicleBase);
