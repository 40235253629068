import type {TabsProps} from 'antd';
import {Form, Input, Select, Tabs} from 'antd';
import {DeviceBase} from "contracts/holotrak/deviceBase";
import {ProductConfigResponse} from "contracts/ProductConfigResponse";
import React from 'react';

interface ISensorTabbedFormProps {
    devices: DeviceBase[];
    productConfigs: ProductConfigResponse;
}

const SensorTabbedForm: React.FC<ISensorTabbedFormProps> = ({devices, productConfigs}) => {
    const items: TabsProps['items'] = [
        {
            key: 'basic',
            label: `Basic Details`,
            children: (
                <>
                    <Form.Item
                        label="Device Id"
                        name="id"
                        hidden
                    >
                        <Input readOnly type="hidden"/>
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please Input Sensor Name!',
                            },
                        ]}
                    >
                        <Input placeholder="Name"/>
                    </Form.Item>

                    <Form.Item
                        label="MAC Address"
                        name="mobileId"
                        rules={[
                            {
                                required: true,
                                message: 'Please Input MAC Address!',
                            },
                        ]}
                    >
                        <Input placeholder="MAC Address"/>
                    </Form.Item>

                    <Form.Item
                        label="Select Device"
                        name="linkedDevice"
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select a device"
                            filterOption={(input, option) => {
                                return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            options={devices?.map((device) => ({
                                label: device.name,
                                value: device.id,
                            }))
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Select Sensor Model"
                        name="deviceModel"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Sensor Model!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Device Model"
                            filterOption={(input, option) => {
                                return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            options={productConfigs?.deviceModels?.map((model) => ({
                                label: model.label,
                                value: model.id,
                            }))
                            }
                        />
                    </Form.Item>
                </>
            ),
        },
    ];

    return (
        <Tabs defaultActiveKey="basic" items={items}/>
    );
};

export default SensorTabbedForm;
