import {Button, Col, Drawer, FloatButton, Row, Spin, Typography} from "antd";
import {BottomBar} from "components/bottom-bar/BottomBar";

import React, {useCallback, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Link, Route, Routes} from "react-router-dom";
import {
    selectGroupMarkedDevices,
    selectFilteredDevices,
    useGetDevicesQuery,
    selectMapBoundDevices, selectMapBoundMovingDevices, useGetDeviceSensorsQuery
} from "redux/services/express";

import GeofenceDrawer from "components/geofence-management/drawer/GeofenceDrawer";

import {useAppDispatch, useTypedSelector} from "redux/store";

import './devices.scss';
import {selectIsShowingGroups, selectLeftDrawerOpen, selectMapConfig, toggleLeftDrawer} from "../../redux/slices/app";
import {IconArrowRightCircle} from "../../assets/icons/icon-arrow-right-circle";

const DeviceList = React.lazy(() => import("./list"));
const DeviceDetails = React.lazy(() => import("components/device-details"));
const Trips = React.lazy(() => import("./trips"));

const MapPane = React.lazy(() => import("components/map-pane"));

export const Devices: React.FC = () => {
    const dispatch = useAppDispatch();

    // Using a query hook automatically fetches data and returns query values
    const {isLoading: isLoadingDevices, error: errorInLoadingDevices, refetch} = useGetDevicesQuery();
    const {isLoading: isLoadingDeviceSensors, error: errorInLoadingDeviceSensors, refetch: refetchDeviceSensors} = useGetDeviceSensorsQuery();

    const mapConfig = useTypedSelector(selectMapConfig);

    const devices = useTypedSelector(selectFilteredDevices);
    const groupMarkedDevices = useTypedSelector(selectGroupMarkedDevices);
    const {still: stillDevices, moving: movingDevices} = useTypedSelector(selectMapBoundMovingDevices);

    const isLeftDrawerOpen = useTypedSelector(selectLeftDrawerOpen);
    const isShowingGroups = useTypedSelector(selectIsShowingGroups);

    const toggleDrawer = useCallback(() => {
        dispatch(toggleLeftDrawer());
    }, [dispatch]);

    const [bottomBarHeight, setBottomBarHeight] = React.useState(0);
    const bottomBarRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (bottomBarRef.current) {
            setBottomBarHeight(bottomBarRef.current.clientHeight);
        }
    }, [bottomBarRef]);

    return (
        <>
            <Helmet>
                <title>Devices</title>
                <meta name='description' content='Holotrak Managed Devices'/>
            </Helmet>

            <Row>
                <Col span={24}>

                    <FloatButton
                        className="left-drawer-toggle"
                        type='default'
                        shape="square"
                        onClick={toggleDrawer}
                        icon={<IconArrowRightCircle/>}
                    />

                    <Drawer
                        placement="left"
                        mask={false}
                        open={isLeftDrawerOpen}
                        closable={false}
                        getContainer={false}
                        motion={null}
                        bodyStyle={{padding: 0}}
                    >
                        <React.Suspense fallback={<Spin spinning={true}/>}>
                            <Routes>
                                <Route path='/' index
                                       element={
                                           <DeviceList
                                               error={errorInLoadingDevices || errorInLoadingDeviceSensors}
                                               isLoading={isLoadingDevices || isLoadingDeviceSensors}
                                               items={devices}
                                           />
                                       }/>
                                <Route path="/:id" element={<DeviceDetails/>}/>
                                <Route path="/:id/trips" element={<Trips/>}/>
                            </Routes>
                        </React.Suspense>
                    </Drawer>
                    <React.Suspense fallback={<Spin spinning={true}/>}>
                        <MapPane
                            consumedHeight={bottomBarHeight}
                            stillItems={stillDevices}
                            movingItems={movingDevices}
                        />
                        <GeofenceDrawer/>
                    </React.Suspense>
                    <Row ref={bottomBarRef}>
                        <Col span={24}>
                            <BottomBar>
                                <Link to={'/report/devices'}>
                                    <Button size='small'>
                                        List
                                    </Button>
                                </Link>
                            </BottomBar>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
