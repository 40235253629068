import dayjs from "dayjs";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {EBatteryLevel} from "../EBatteryLevel";
import {EDeviceSignal, EDeviceStatus, ELosantDeviceClass, ESensorType, getSensorTypeFromString} from "../EDeviceStatus";
import {IDeviceStatus} from "../IDeviceStatus";
import {IHasCompositeState} from "../IHasCompositeState";
import {IHasTags} from "../IHasTags";
import {IReportableItem} from "../IOutdoorLocatable";
import {CompositeState, CompositeStateParser, CompositeStateType} from "./compositeState";
import {CompositeStateItem, CompositeStateItemSerializers} from "./compositeStateItem";
import {ConnectionInfo} from "./connectionInfo";
import {DeviceAttribute, DeviceTag, DeviceTagSerializer} from "./deviceTag";
import {GeofenceSlotConfig} from "./GeofenceSlotConfig";
import {MqttMessage} from "./mqttMessage";

@jsonObject()
export class LosantCoreDevice implements IHasTags, IHasCompositeState, IReportableItem {

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    creationDate: dayjs.Dayjs;

    @jsonMember(String)
    name: string;

    @jsonMember(() => ELosantDeviceClass, {
        deserializer: (value: any) => {
            switch (value) {
                case 'gateway':
                case ELosantDeviceClass.GATEWAY:
                    return ELosantDeviceClass.GATEWAY;

                case 'floating':
                case ELosantDeviceClass.FLOATING:
                    return ELosantDeviceClass.FLOATING;

                case 'device':
                default:
                    return ELosantDeviceClass.DEVICE;
            }
        }
    })
    deviceClass: ELosantDeviceClass;

    @jsonArrayMember(DeviceTag, {
        deserializer: (value: any) => {
            return LosantCoreDevice.parseTags({tags: value});
        }
    })
    tags: DeviceTag[] | string;

    @jsonArrayMember(DeviceAttribute)
    attributes?: DeviceAttribute[];

    @jsonMember(String)
    applicationId: string
    @jsonMember(String)
    _etag: string
    @jsonMember(String)
    deviceId: string
    @jsonMember(String)
    id: string
    @jsonMember(ConnectionInfo)
    connectionInfo: ConnectionInfo
    @jsonMember(CompositeState)
    compositeState: CompositeState;
    @jsonMember(String)
    group: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    lastUpdated: dayjs.Dayjs;


    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;


    get type(): string {
        return this.getTagValue('type');
    }

    get additionalInfo(): string {
        return this.getCompositeValue('address', '', '', false);
    }

    get uiHeight(): number {
        return (this.additionalInfo || this.additionalInfo === '') ? 111 : 84;
    }

    get status(): EDeviceStatus {
        if (this.getTagValue('sensor_type') === 'alarms') {
            return this.isAlarmArmed ? EDeviceStatus.ALARM_ARMED : EDeviceStatus.ALARM_DISARMED;
        } else {
            return this.compositeState?.computeOutdoorStatus() ?? EDeviceStatus.NEVER_CONNECTED;
        }
    }

    get signalStrength(): EDeviceSignal {
        return this.compositeState.computeSignalStrength() ?? EDeviceSignal.NONE;
    }

    get statusInfo(): IDeviceStatus {
        return {
            icon: 'fa fa-circle',
            updatedText: this.lastUpdatedOn?.fromNow() || '--',
            value: this.status
        }
    }

    get lastUpdatedOn(): dayjs.Dayjs {
        // FIXME: never connected should be never connected.
        return this.getCompositeStateItem('last_reported_at')?.updatedAt ?? this.lastUpdated ?? null;
    }

    get batteryText(): string {
        return this.getCompositeValue('battery_level', '%', 'NA', false);
    }

    get batteryLevel(): EBatteryLevel {
        return this.compositeState?.computeBatteryLevel();
    }

    get sensorType(): ESensorType {
        return getSensorTypeFromString(this.getTagValue('sensor_type'));
    }

    get isMappable(): boolean {
        return true;
    }

    get deviceModel(): string {
        return this.getTagValue('device_model');
    }

    static parseTags(device: IHasTags): DeviceTag[] {
        return (typeof device.tags === 'string')
            ? DeviceTagSerializer?.parseAsArray(JSON.parse(device.tags))
            : device?.tags;
    }

    getTagValue(name: string): string | null {
        if (typeof this.tags === 'string') {
            return null;
        }

        const qualifiedTagName = (name === 'device_type') ? 'type' : name;
        const value = this.tags?.find(tag => tag.key === qualifiedTagName)?.value || null;

        if (name === 'type' && value) {
            switch (`${value}`.toLowerCase()) {
                case 'calamp':
                    return 'Smart Tracker';

                case 'external-sensor':
                    return 'External';

                case 'atrack':
                    return 'ATrack';

                case 'morey':
                    return 'Morey';

                case 'queclink':
                    return 'Queclink';

                default:
                    return value;
            }
        }

        return value;
    }

    updateState(message: MqttMessage) {
        if (!this.compositeState) {
            this.compositeState = CompositeStateParser.parse({});
        }

        const stateKeys = Object.keys(this.compositeState ?? {}) as CompositeStateType[];

        stateKeys.forEach(itemKey => {
            this.compositeState[itemKey] = (message[itemKey] === undefined || message[itemKey] === null)
                ? this.compositeState[itemKey]
                : CompositeStateItemSerializers[itemKey].parse({
                    time: message.last_reported_at,
                    value: message[itemKey]
                });
        });

        if (stateKeys.includes('location') || stateKeys.push('address')) {
            this.compositeState.last_reported_at = CompositeStateItemSerializers.last_location_updated_at.parse({
                time: dayjs(),
                value: dayjs()
            });
        }
    }

    getCompositeStateItem(index: CompositeStateType): CompositeStateItem {
        return this.compositeState?.getItem(index);
    }


    // INFO: Use getCompositeBooleanValue for boolean responses
    getCompositeValue(index: CompositeStateType, suffix: string = '', fallback: string = '-', alwaysShowSuffix: boolean = true) {
        const item = this.getCompositeStateItem(index);

        if (!item?.hasValue() || this.shouldItemValueBeHidden(item, index)) {
            return (alwaysShowSuffix) ? fallback + ` ${suffix}` : fallback;
        }

        const itemValue = item.toString() + ` ${suffix}`;
        return `${itemValue}`.trim();
    }

    // FIXME: This is a hack to get the boolean value from the composite state. Because BooleanCompositeStateItem toString returns Yes/No
    getCompositeBooleanValue(index: CompositeStateType, fallback: boolean = false) {
        return this.getCompositeValue(index, '', '') === 'Yes' ?? fallback;
    }

    shouldItemValueBeHidden(item: CompositeStateItem, index: CompositeStateType) {
        return (this.sensorType === ESensorType.MILLER_CAN) ? !item?.hasValidMillerValue(index) : false;
    }

    getDateString(date: number | string | dayjs.Dayjs, fallback = '--'): string {
        if (date) {
            return dayjs(date).toISOString();
        }

        return fallback;
    }

    geofenceSlotSupported(slot: GeofenceSlotConfig): boolean {
        const deviceType = this.getTagValue('device_type');

        return (deviceType && deviceType.toLowerCase() === slot.deviceModel.toLowerCase());
    }

    get isAlarmArmed(): boolean {
        return this.getCompositeBooleanValue('is_alarm_armed');
    }
}


export const LosantCoreDeviceSerializer = new TypedJSON(LosantCoreDevice);
