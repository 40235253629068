import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {Device} from "./device";
import {VehicleBase, VehicleBaseSerializer} from "./vehicleBase";
import {DriverBase} from "./driverBase";
import {LosantPagination} from "./losantPagination";
import {Vehicle} from "./vehicle";
import {IOutdoorLocatable} from "../IOutdoorLocatable";
import dayjs from "dayjs";
import {EDeviceStatus, ELosantDeviceClass} from "../EDeviceStatus";
import {IDeviceStatus} from "../IDeviceStatus";
import {EBatteryLevel} from "../EBatteryLevel";
import {EDeviceCommandTypes} from "../EDeviceCommandTypes";
import L from "leaflet";
import {IMappable} from "../IMappable";
import {CompositeState, CompositeStateType} from "./compositeState";
import {CompositeStateItem} from "./compositeStateItem";

@jsonObject()
export class Driver extends DriverBase implements IOutdoorLocatable, IMappable {
    @jsonMember(Device)
    device: Device;

    @jsonMember(VehicleBase, {
        name: 'vehicleData',
        deserializer: (value: any) => {
            try {
                return value ? VehicleBaseSerializer.parse(value) : null;
            } catch (e) {
                return null;
            }
        }
    })
    vehicle?: VehicleBase;

    get vehicleId(): string {
        return this.vehicle?.id ?? '';
    }

    set vehicleId(value: string) {
        if (this.vehicle) {
            this.vehicle.id = value;
        } else {
            this.vehicle = new VehicleBase();
            this.vehicle.id = value;
        }
    }

    get isAlarmArmed(): boolean {
        return this.device?.isAlarmArmed ?? false;
    }

    get uiHeight(): number {
        return (this.additionalInfo || this.additionalInfo === '') ? 111 : 84;
    }

    get additionalInfo(): string {
        return this.device?.additionalInfo || '';
    }

    get creationDate(): dayjs.Dayjs {
        return this.device?.creationDate;
    }

    get deviceClass(): ELosantDeviceClass {
        return this.device?.deviceClass;
    }

    get lastUpdated(): dayjs.Dayjs {
        return this.device?.lastUpdated;
    }

    get mobileId(): string {
        return this.device?.mobileId;
    }

    get status(): EDeviceStatus {
        return this.device?.status;
    }

    get statusInfo(): IDeviceStatus {
        return this.device?.statusInfo;
    }

    get type(): string {
        return this.device?.type;
    }

    get batteryLevel(): EBatteryLevel {
        return this.device?.batteryLevel;
    }

    get batteryText(): string {
        return this.device?.batteryText;
    }

    get compositeState(): CompositeState {
        return this.device?.compositeState;
    }

    get isMoving(): boolean {
        return this.device?.isMoving;
    }

    get isOffline(): boolean {
        return this.device?.isOffline;
    }

    get latLng(): L.LatLng {
        return this.device?.latLng;
    }

    get isMappable(): boolean {
        return true;
    }

    getCommand(commandType: EDeviceCommandTypes): string | void {
        return this.device?.getCommand(commandType);
    }

    getTagValue(name: string): string | null {
        return this.device?.getTagValue(name) ?? null;
    }

    getCompositeStateItem(index: CompositeStateType): CompositeStateItem {
        return this.device?.getCompositeStateItem(index);
    }

    getCompositeValue(index: CompositeStateType, suffix: string, fallback: string, alwaysShowSuffix: boolean): string {
        return this.device?.getCompositeValue(index, suffix, fallback, alwaysShowSuffix);
    }
}

@jsonObject()
export class DriverPaginatedList extends LosantPagination {
    @jsonArrayMember(Driver)
    items: Driver[];
}

export type IDriverSaveRequest = {
    data: Partial<Driver>;
}

export const DriverSerializer = new TypedJSON(Driver);
